<template>
  <div ref="subsidies" class="subsidies" :style="{'background': background}">
    <div v-if="this.activityInfo.activityImage">
      <div class="top">
        <img :src="activityInfo.activityImage"/>
      </div>
      <div class="bottom">
        <div class="image">
          <img :src="buttonImage"
               v-if="channel !== 'weixin' && channel !== 'zfb'" @click="isAuth('jump')"/>
        </div>
        <div v-if="material == 'tb' && materialId" class="category-list">
          <template v-for="(item, index) in categoryList">
            <div :key="index" :class="[item.selected ? 'scroll-view-item-active' : '','scroll-view-item']"
                 @click="handleChangeCat(item.code)">
              <p>{{ item.desc }}</p>
              <div v-if="item.selected" class="active-tab"></div>
            </div>
          </template>
        </div>
        <div v-if="material == 'tb' && materialId" class="goods-list" :style="{background: goodsList.length > 0 ? '' : '#ffffff'}">
          <template v-for="(item, index) in goodsList">
            <div class="goods-wrap" :key="index">
              <div class="goods-image" @click="goodsDetails(item)">
                <img v-lazy="item.pictUrl"/>
              </div>
              <div class="goods-info"  @click="goodsDetails(item)">
                <div class="goods-title">
                  <span class="label-tb">淘宝</span>
                  <!-- <image class="jd-icon" azy-load="true" src="https://cdn.cailu88.com/jingxuanshi/jd_icon@2x.png"></image> -->
                  <span>{{ item.itemTitle }}</span>
                </div>
                <div class="goods-discounts">
                  <div class="goods-coupon" v-if="item.couponValue">
                    <span>{{ item.couponValue }}元券</span>
                  </div>
                  <div v-if="item.rebateValue>0" class="goods-cashback">返 ¥{{ item.rebateValue }}</div>
                </div>
                <div class="goods-price-volume">
                  <div class="price">¥ {{ item.reservePrice }}</div>
                </div>
                <div class="goods-promotion-volume">
                  <div>
                    <span class='money-symbol'>¥ </span>
                    <span class="goods-current-price">{{ item.zkFinalPrice }}</span>
                  </div>
                </div>
              </div>
              <div class="goods-link-copy">
                <span @click="copyUrl($event, item)">复制口令</span>
              </div>
            </div>
          </template>
          <div :class="['loading-wrap', goodsList.length < 5 ? 'loading-wrap-white':'']">
            <img v-if="goodsList.length < totalCount" class="loading"
                 src="https://cdn.cailu88.com/jingxuanshi/loading@2x.png"/>
            <p v-if="goodsList.length === totalCount">
              暂无更多
            </p>
          </div>
        </div>
        <div v-else class="content">
          <div style="padding: 20px 28px">
            <p class="copy">{{ copyContent ? copyContent : activityInfo.activityDesc }}</p>
            <div v-if="material == 'tb'">
              <van-button :style="{'background': buttonColor}" @click="copyText($event)">
                复制口令
              </van-button>
            </div>
            <div v-else>
              <van-button :style="{'background': buttonColor}" @click="copyText($event)">复制链接</van-button>
            </div>
            <p class="text" :style="{'color': buttonColor}">
              <span>复制文案</span>
              <van-icon name="arrow"/>
              <span>好友下单</span>
              <van-icon name="arrow"/>
              <span>你赚佣金</span>
            </p>
          </div>
        </div>
      </div>
      <div class="footer-button" v-if="material == 'tb' && materialId">
        <span @click="copyText($event)">分享会场口令</span>
      </div>
    </div>
    <Loading :click="click"></Loading>
    <!--    <ForceUpdate class="popup" v-if="show"></ForceUpdate>-->
  </div>
</template>
<script>
import Vue from 'vue'
import { Button, Icon, Lazyload, Toast } from 'vant'
import Loading from '../../components/loading'
import baseUrl from '@/utils/baseUrl'
import clipboard from '@/utils/copy'
import ForceUpdate from '@/components/forceUpdate'

const api = require('../../utils/api').api

Vue.use(Button)
Vue.use(Icon)
Vue.use(Toast)
Vue.use(Lazyload, {
  lazyComponent: true,
})

export default {
  name: 'subsidies',
  data () {
    return {
      //  类目列表
      //  新人一分购：41539  特价一元起：42563  厂货限量：42616  超值爆款：42705
      //  30%限量高佣投放ID：41515  全量API投放ID：41514 买贵必赔投放ID：41511  特价版投放ID：41477 特价版一分购商品投放ID：41539
      //  5月份：以货拉新一分购 42556 优质好货明星爆款： 41823（包含3元以下） 41824（3元以下） 超值爆款买到即赚到（淘礼金精选好货）42563  淘宝特价版全量商品投放ID：41477
      categoryList: [
        {
          desc: '一分购',
          code: 42556,
          selected: true
        },
        {
          desc: '好货爆款',
          code: 41823,
          selected: false
        },
        {
          desc: '超值3元',
          code: 41824,
          selected: false
        },
        {
          desc: '超值爆款',
          code: 42563,
          selected: false
        },
        {
          desc: '特价好货',
          code: 41477,
          selected: false
        }
      ],
      materialId: '',
      //  类目id
      catId: '41515',
      // 商品列表
      goodsList: [],
      // 商品列表分页
      totalCount: 0,
      page: 0,
      pageSize: 10,
      authStatus: false,
      show: true,
      token: '',
      version: '',
      channel: '',
      appClient: '',
      clientType: '',
      url: '',
      buttonImage: '',
      activityDesc: '',
      copyContent: '',
      click: true,
      id: '',
      activityId: '',
      background: '',
      buttonColor: '',
      dataParams: {},
      material: '',
      activityInfo: {},
    }
  },
  components: {
    Loading,
    ForceUpdate
  },
  created () {
  },
  mounted () {
    this.token = this.$route.query.token || ''
    this.version = this.$route.query.version || ''
    this.confirmVersion()
    this.channel = this.$route.query.channel || ''
    this.appClient = this.$route.query.appClient || ''
    this.clientType = this.$route.query.clientType || ''
    this.activityId = this.$route.query.activityId
    this.handleGetActivity()
    this.handleGetGoodsList()
    window.addEventListener('scroll', this.lazyLoading)
    // 通过$on,$once使用hook监听vue生命周期函数，进行监听销毁
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', this.lazyLoading)
    })
  },
  methods: {
    confirmVersion () {
      let u = window.navigator.userAgent
      let isIOSApp = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)  // ios终端
      let isAndroidApp = (u.indexOf('Android') > -1) // android
      let arr = this.version.split('.')
      if (Number(arr[0]) > 0) {
        this.show = false
      } else if (Number(arr[1]) > 1) {
        this.show = false
      } else if ((Number(arr[2]) > 5 && isAndroidApp) || (Number(arr[2]) >= 5 && isIOSApp)) {
        this.show = false
      }
    },

    //复制
    copyText (event) {
      let that = this
      if (!this.click) {
        return false
      }
      this.click = true
      if (that.material == 'tb') {
        this.$.ajax({
          url: baseUrl.baseUrl + api.auth,
          type: 'get',
          headers: {
            'token': that.token,
            'version': that.version,
            'channel': that.channel,
            'appClient': that.appClient,
            'clientType': that.clientType,
            'Content-Type': 'application/json;charset=UTF-8'
          },
          async: false,
          success: function (res) {
            if (res.code !== 'USER_UN_LOGIN' && res.code) {
              if (res.data.authStatus == 0) {
                that.click = true
                that.$h5AppLink('openTbAuth', {
                  authUrl: res.data.authUrl,
                  backUrl: `${baseUrl.pageUrl}/template?activityId=${that.activityId}`
                })
              } else {
                that.$.ajax({
                  url: baseUrl.baseUrl + api.strategyUrl,
                  type: 'post',
                  headers: {
                    'token': that.token,
                    'version': that.version,
                    'channel': that.channel,
                    'appClient': that.appClient,
                    'clientType': that.clientType,
                    'Content-Type': 'application/json;charset=UTF-8'
                  },
                  data: JSON.stringify(that.dataParams),
                  async: false,
                  success: function (res_t) {
                    that.click = true
                    Toast.success({
                      duration: 500, // 持续展示 toast
                      message: '复制成功'
                    })
                    that.copyContent = res_t.data.shareContent
                  }
                })
              }
            } else {
              that.click = true
              that.isLogin()
              return false
            }

          }
        })
      } else if (that.material == 'pdd') {
        this.$.ajax({
          url: baseUrl.baseUrl + api.strategyUrl,
          type: 'post',
          headers: {
            'token': that.token,
            'version': that.version,
            'channel': that.channel,
            'appClient': that.appClient,
            'clientType': that.clientType,
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: JSON.stringify({
            shareScene: true,
            pddActivityMaterialId: that.id,
            initUrl: that.materialId,
            clientType: 2,
            activityId: this.activityInfo.id,
            activityDesc: this.activityInfo.activityDesc
          }),
          async: false,
          success: function (res) {
            if (res.code != 'USER_UN_LOGIN' && res.code) {
              that.click = true
              Toast.success({
                duration: 500, // 持续展示 toast
                message: '复制成功'
              })
              that.copyContent = res.data.shareContent || that.activityDesc[0] + '！' + res.data.sourceContent
            } else {
              that.click = true
              that.isLogin()
              return false
            }
          }
        })
      } else if (that.material == 'jd' || that.material == 'sn' || that.material == 'wph') {
        this.$.ajax({
          url: baseUrl.baseUrl + '/item/transfer',
          type: 'get',
          headers: {
            'token': that.token,
            'version': that.version,
            'channel': that.channel,
            'appClient': that.appClient,
            'clientType': that.clientType,
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            shareScene: true,
            content: this.dataParams.content,
            clientType: 2
          },
          async: false,
          success: function (res) {
            if (res.code != 'USER_UN_LOGIN' && res.code) {
              that.click = true
              Toast.success({
                duration: 500, // 持续展示 toast
                message: '复制成功'
              })
              that.copyContent = that.activityDesc[0] + '！' + res.data.spreadContent
            } else {
              that.click = true
              that.isLogin()
              return false
            }
          }
        })
      }
      this.click = true
      clipboard(this.copyContent, event)
    },

    //获取活动数据
    handleGetActivity () {
      this.$axios(api.activityInfo, {
        params: {
          activityId: this.activityId,
          clientType: 2,
        },
      }).then(res => {
        this.activityInfo = res.data.data
        let source = this.activityInfo.zoneSource
        let { venueCode, sourceCode, buttonImage, backgroundColor, buttonColor } = this.activityInfo.activityVenueExt
        this.activityDesc = this.activityInfo.activityDesc.split('！')
        this.id = venueCode
        this.materialId = sourceCode
        this.buttonImage = buttonImage
        this.background = backgroundColor
        this.buttonColor = buttonColor
        this.material = source == 1 ? 'tb' : source == 2 ? 'jd' : source == 3 ? 'pdd' : source == 5 ? 'sn' : source == 4 ? 'wph' : ''
        document.title = this.activityInfo.activityName
        if (this.material == 'tb') {
          this.dataParams.tbActivityMaterialId = this.id
        } else if (this.material == 'pdd') {
          this.dataParams.pddActivityMaterialId = this.id
          this.dataParams.initUrl = this.materialId
        } else {
          this.dataParams.content = this.materialId
        }
        this.dataParams.activityId = this.activityInfo.id
        this.dataParams.activityDesc = this.activityInfo.activityDesc
        this.dataParams.shareScene = true
        this.dataParams.clientType = 2
      }).catch(err => {
        this.isLogin()
      })
    },

    // 进入会场
    jump () {
      let eventName = ''
      let paramObj = {}
      if (this.material == 'tb') {
        paramObj = {
          version: this.version,
          url: this.url,
          backUrl: `${baseUrl.pageUrl}/template?activityId=${this.activityId}`
        }
        this.$h5AppLink('version', paramObj)
      } else if (this.material == 'jd') {
        eventName = 'jumpJD'
        paramObj = {
          url: this.url,
        }
        this.$h5AppLink(eventName, paramObj)
      }

    },

    // 授权
    isAuth (type) {
      if (!this.click) {
        return false
      }
      if (this.url) {
        this.click = true
        setTimeout(() => {
          if (this.material == 'tb' || this.material == 'jd') {
            this.jump()
          } else {
            window.open(this.url)
          }
        })
        return
      }
      if (this.material == 'tb') {
        this.$axios(api.auth, {}).then(res => {
          this.click = true
          if (res.data.data.authStatus == 0) {
            let event = 'openTbAuth'
            let param = {
              authUrl: res.data.data.authUrl,
              backUrl: `${baseUrl.pageUrl}/template?activityId=${this.activityId}`
            }
            this.$h5AppLink(event, param)
          } else {
            this.authStatus = true
            this.handleGetUrl(type)
          }
        }).catch(err => {
          this.click = true
          this.isLogin()
        })
      } else {
        this.handleGetUrl(type)
      }
    },

    // 获取url
    handleGetUrl (type) {
      if (this.material == 'jd' || this.material == 'sn' || this.material == 'wph') {
        this.$axios('/item/transfer', {
          params: {
            content: this.dataParams.content,
            clientType: 2
          },
        }).then(res => {
          this.url = res.data.data.spreadContent
          this.copyContent = this.activityDesc[0] + '！' + res.data.data.spreadContent
          this.click = true
          if (type == 'copy') {
            return false
          }
          setTimeout(() => {
            if (this.material == 'jd') {
              this.jump()
            } else {
              window.open(this.url)
            }
          })
        }).catch(err => {
          Toast.fail(err.data.message)
          this.click = true
          this.isLogin()
        })
      } else {
        this.$axios.post(api.strategyUrl, this.dataParams).then(res => {
          this.url = res.data.data.appSpreadUrl || res.data.data.spreadUrl
          this.copyContent = res.data.data.shareContent || this.activityDesc[0] + '！' + res.data.data.sourceContent
          this.click = true
          if (type == 'copy') {
            return false
          }
          setTimeout(() => {
            if (this.material == 'tb') {
              this.jump()
            } else {
              let param = {
                url: this.url
              }
              this.$h5AppLink('openWebview', param)
            }
          }, 100)
        }).catch(err => {
          Toast.fail(err.data.message)
          this.click = true
          this.isLogin()
        })
      }
    },

    /*
    切换类目点击事件
     */
    handleChangeCat (id) {
      this.page = 0
      this.catId = id
      this.categoryList.map(item => {
        if (item.code === id) {
          item.selected = true
        } else {
          item.selected = false
        }
      })
      this.handleGetGoodsList()
    },

    /*
    * */
    lazyLoading () {
      // 获取 可视区高度`、`滚动高度`、`页面高度`
      let timer = null
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let clientHeight = document.documentElement.clientHeight
      let scrollHeight = document.documentElement.scrollHeight
      this.scroll = scrollHeight
      this.height = scrollTop + clientHeight
      if (scrollTop + clientHeight + 10 >= scrollHeight) { // 滚动到底部，逻辑代码
        //事件处理
        // this.page++
        this.handleGetGoodsList('up')
        // if (this.goodsList.length < this.totalCount && this.totalCount !== 0) {
        //   this.handleGetGoodsList('up')
        // }
      }
    },

    /*
    获取商品列表
     */
    handleGetGoodsList (type) {
      if (!this.click) {
        return false
      }
      this.page++
      this.click = false
      this.$axios(api.activityMaterialsGoods, {
        params: {
          materialId: this.catId,
          page: this.page,
          pageSize: this.pageSize,
        }
      }).then(res => {
        this.goodsList = type == 'up' ? [...this.goodsList, ...res.data.data.data] : res.data.data.data
        this.goodsList ? this.goodsList.map(item => {
          // 新人一分购：41539 价格改为0.01
          item.zkFinalPrice = this.catId == '41539' ? '0.01' : item.zkFinalPrice
        }) : []
        this.totalCount = res.data.data.totalCount
        this.click = true
      }).catch(err => {
        this.click = true
        Toast.fail(err.data.message)
        if (err.data.code == 'USER_UN_LOGIN'){
          this.isLogin()
        }
        console.info(err)
      })
    },

    /*
    点击商品复制口令   goodsTransfer
     */
    copyUrl (event, item) {
      let that = this
      if (!this.click) {
        return false
      }
      this.click = true
      this.$.ajax({
        url: baseUrl.baseUrl + api.auth,
        type: 'get',
        headers: {
          'token': that.token,
          'version': that.version,
          'channel': that.channel,
          'appClient': that.appClient,
          'Content-Type': 'application/json;charset=UTF-8'
        },
        async: false,
        success: function (res) {
          if (res.code !== 'USER_UN_LOGIN' && res.code) {
            if (res.data.authStatus == 0) {
              that.click = true
              that.$h5AppLink('openTbAuth', {
                authUrl: res.data.authUrl,
                backUrl: `${baseUrl.pageUrl}/template?activityId=${this.activityId}`
              })
            } else {
              that.$.ajax({
                url: baseUrl.baseUrl + api.goodsTransfer + `?itemSource=${item.itemSource}&itemId=${item.itemId}&itemTitle=${item.itemTitle}&pictUrl=${item.pictUrl}&clientType=2`,
                type: 'get',
                headers: {
                  'token': that.token,
                  'version': that.version,
                  'channel': that.channel,
                  'appClient': that.appClient,
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                async: false,
                success: function (res_t) {
                  that.click = true
                  Toast.success({
                    duration: 500, // 持续展示 toast
                    message: '复制成功'
                  })
                  that.copyContent = '1【'+ item.itemTitle +'】復製' + res_t.data.spreadUrl + '，进入【T*ℬ特价版】抢福利:/'
                }
              })
            }
          } else {
            that.click = true
            that.isLogin()
            return false
          }

        }
      })
      this.click = true
      clipboard(this.copyContent, event)
    },

    /*
    商品跳转淘宝
     */
    goodsDetails (item) {
      this.$axios(api.auth, {}).then(res => {
        this.click = true
        if (res.data.data.authStatus == 0) {
          let event = 'openTbAuth'
          let param = {
            authUrl: res.data.data.authUrl,
            backUrl: `${baseUrl.pageUrl}/template?activityId=${this.activityId}`
          }
          this.$h5AppLink(event, param)
        } else {
          this.authStatus = true
          this.$axios(api.goodsJump, {
            params:{
              itemSource: item.itemSource,
              itemId: item.itemId,
              itemTitle: item.itemTitle,
              pictUrl: item.pictUrl
            }
          }).then(res => {
            this.url = res.data.data.spreadUrl
            this.jump()
          }).catch(err => {
            console.info(err)
          })
        }
      }).catch(err => {
        this.click = true
        this.isLogin()
      })
    },

    // 登陆
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/template?activityId=${this.activityId}`
      }
      this.$h5AppLink(event, param)
    },

  },
}
</script>
<style lang="less" scoped>
.subsidies {
  min-height: 100vh;

  .popup {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  .top {
    line-height: 0;

    img {
      width: 100%;
    }
  }

  .bottom {
    width: 100%;
    text-align: center;

    .image {
      margin-top: 16px;
      margin-bottom: 36px;

      img {
        width: 275px;
      }
    }

    .content {
      margin: 0 auto;
      width: 340px;
      height: 210px;
      background: #FFFFFF;
      border-radius: 12px;

      div {
        display: flex;
        flex-direction: column;
      }

      p {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }

      .copy {
        margin-bottom: 20px;
        line-height: 21px;
        word-break: break-all;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; //想显示多少行就给多大值
        -webkit-box-orient: vertical;
      }

      .van-button {
        margin: 0 auto;
        width: 250px;
        height: 50px;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        border-radius: 25px;
        border: none;
      }

      .text {
        margin-top: 15px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        .van-icon {
          margin: 0 12px;
          font-weight: bold;
        }
      }
    }

    .category-list {
      margin-top: 5px;
      padding-left: 10px;
      width: 95%;
      height: 45px;
      white-space: nowrap;
      overflow-x: auto;

      .scroll-view-item {
        margin-right: 28px;
        color: #333;
        display: inline-block;

        & > p:first-child {
          font-size: 14px;
          line-height: 20px;
          color: #FFFFFF;
        }

        .active-tab {
          width: 20px;
          height: 4px;
          background: #ffea1c;
          border-radius: 2px;
        }
      }

      scroll-view-item:first-child {
        padding-left: 5px;
      }

      .scroll-view-item-active {
        & > p:first-child {
          font-size: 16px;
          font-weight: bold;
          line-height: 25px;
          color: #FFEA1C;
        }
      }
    }

    .goods-list {
      width: 100%;
      min-height: 532px;
      padding-top: 8px;
      padding-bottom: 8px;

      .goods-wrap {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0px 8px 6px 8px;
        padding: 10px;
        max-height: 140px;
        background: #fff;
        border-radius: 8px;

        .goods-image {
          & > img {
            width: 120px;
            height: 120px;
            border-radius: 8px;
          }
        }

        .goods-info {
          margin-left: 10px;

          .goods-title {
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            color: #333333;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;

            & > span:first-child {
              font-size: 10px;
              color: #fff;
              margin-right: 7px;
              border-radius: 2px;
            }

            .label-tb {
              font-size: 8px;
              padding: 1px 4px;
              background: #FF8502;
            }

            .label-tm {
              font-size: 8px;
              padding: 1px 4px;
              background: #E44A59;
            }

            .label-jd {
              font-size: 8px;
              padding: 1px 4px;
              background-color: #e44a59;
            }

            .label-pdd {
              font-size: 8px;
              padding: 1px 3px;
              background-color: #ff5d87;
            }

            .label-wph {
              font-size: 8px;
              padding: 1px 3px;
              background-color: #ed008c;
            }

            .label-sn {
              font-size: 8px;
              padding: 1px 3px;
              background-color: #ffaa02;
            }
          }

          .goods-discounts {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 20px;
            font-size: 12px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            /* color: #8b7f08; */
            line-height: 20px;
            margin-top: 3px;

            .goods-coupon,
            .goods-cashback {
              color: #fff;
              background-color: #E44A59;
              border-radius: 2px;
            }

            .goods-cashback {
              padding: 0 4px;
              color: #FF3B3C;
              background: rgba(255, 59, 60, 0.1);
            }

            .goods-coupon {
              padding: 0 8px 0 8px;
              margin-right: 8px;
              position: relative;
              text-align: center;
              width: 48px;
              height: 20px;
              background: url("https://cdn.cailu88.com/jingxuanshi/client_activity_coupon.png");
              background-size: cover;
              /* overflow: hidden; */
            }
          }

          .goods-price-volume {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 12px;

            .price {
              color: #999;
              text-decoration: line-through;
            }
          }

          .goods-promotion-volume {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .money-symbol {
              font-size: 13px;
              font-weight: bold;
              color: #e44a59;
              line-height: 18px;
            }

            .goods-current-price {
              height: 30px;
              font-size: 22px;
              font-weight: bold;
              color: #e44a59;
              line-height: 30px;
            }
          }

          .goods-price-volume .price {
            height: 17px;
            font-size: 12px;
            line-height: 17px;
          }
        }

        .goods-link-copy {
          position: absolute;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 7px;
          bottom: 8px;
          width: 82px;
          height: 34px;
          background: #FF3B3C;
          border-radius: 100px 0px 0px 100px;

          span {
            font-size: 15px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 21px;
          }
        }
      }

      .loading-wrap {
        font-size: 12px;
        line-height: 17px;
        color: #b6b6b6;
        text-align: center;
        padding: 15px 0;

        .loading {
          width: 35px;
          height: 35px;
          animation: loading 1s linear 0s infinite;
        }

        @keyframes loading {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }

      .loading-wrap-white {
        margin-top: 6px;
        background-color: #fff;
      }
    }
  }

  .footer-button {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);
    span {
      width: 300px;
      height: 45px;
      text-align: center;
      background: linear-gradient(90deg, #FF004F 0%, #FF5500 100%);
      box-shadow: 0px 2px 4px 0px rgba(255, 31, 50, 0.6);
      border-radius: 25px;
      font-size: 18px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 45px;
    }
  }
}
</style>
