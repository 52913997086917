<template>
  <div class="force-update">
    <div class="content">
      <img class="content-top-img" src="https://cdn.cailu88.com/jingxuanshi/client_force_update_1.png"/>
      <img class="content-main-img" src="https://cdn.cailu88.com/jingxuanshi/client_force_update_2.png"/>
      <div class="text">
        <div>
          <div class="dot"></div>
          <p>升级更多好玩内容</p>
        </div>
        <div>
          <div class="dot"></div>
          <p>先领券再购买，少花多赚</p>
        </div>
        <div>
          <div class="dot"></div>
          <p>花更少的钱，买更好的货</p>
        </div>
      </div>
      <van-button class="button" @click="handleUpdate">立即更新</van-button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Button } from 'vant';

Vue.use(Button);
export default {
  name: 'forceUpdate',
  data(){
    return{
      show: true,
    }
  },
  methods:{
    //立即更新
    handleUpdate(){
      let u = window.navigator.userAgent
      let isIOSApp = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)  // ios终端
      let isAndroidApp = (u.indexOf('Android') > -1) // android
      if (isIOSApp) {
        this.terminal = 'ios'
        // this.buttonText = '敬请期待';
        window.open('https://apps.apple.com/cn/app/id1533950475')
        return
      } else if (isAndroidApp) {
        if (this.isAliApp) {
          this.handleCopy();
        } else if (this.isWx){
          window.open('https://a.app.qq.com/o/simple.jsp?pkgname=com.duilu.jxs')
        } else{
          this.terminal = 'android'
          window.open('https://cdn.cailu88.com/jingxuanshi/apk/jxs_release_0.6.1.apk')
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.force-update{
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  .content{
    position: relative;
    text-align: center;
    width: 320px;
    background: #ffffff;
    border-radius: 20px;
    img{
      width: 100%;
    }
    .content-top-img{
      position: absolute;
      left: 120px;
      top: -44px;
      width: 81px;
    }
    .content-main-img{
      border-radius: 20px 20px 0 0;
    }
    .text {
      margin: 22px;
      div{
        display: flex;
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 7px;
        .dot{
          margin-top: 6px;
          margin-right: 11px;
          display: inline-block;
          line-height: 21px;
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background: #FFEA1C;
        }
        p{
          text-align: left;
          line-height: 21px;
        }
      }
    }
    .button{
      margin-bottom: 24px;
      width: 280px;
      height: 50px;
      background: #FFEA1C;
      box-shadow: 0px 3px 5px 1px rgba(255, 234, 28, 0.5);
      border-radius: 25px;
      color: #333333;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
</style>
